<!--事件处理-红码处理-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-cascader
              placeholder="所属单位"
              @change="getData"
              v-model="filter.Owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="filter.create_at"
              type="date"
              @change="getData"
              placeholder="派单日期"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.Status"
              filterable
              clearable
              placeholder='事件状态'
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="filter.rectify_status"
              placeholder="整改状态"
              clearable
              @keyup.native.enter="getData"
              @change="getData"
            >
              <el-option value="1" label="整改中"/>
              <el-option value="2" label="整改完成"/>
              <el-option value="3" label="整改未完成"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" width="55" />
          <el-table-column
            prop="LocaleName"
            label="监测点"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="MN"
            label="设备编号"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="StartAt"
            :formatter="dateFormatter"
            label="起始日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="CompliantDays"
            label="达标天数"
            width="100"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.CompliantDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ExcessDays"
            label="超标天数"
            width="100"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.ExcessDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FilterAbnormallyUsedDays"
            label="不正常使用净化器天数"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.FilterAbnormallyUsedDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="OfflineCausedByShopDays"
            label="店家原因离线天数"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.OfflineCausedByShopDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Status"
            :formatter="statusFormatter"
            label="事件状态"
            align="center"
            width="80"
            header-align="center"
          />
          <el-table-column
            prop="CreateAt"
            :formatter="dateFormatter"
            label="派单日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="VerifyAt"
            :formatter="dateFormatter"
            label="核实日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="Verifier"
            label="核实人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="RectifyReason"
            label="红码原因"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="Cause"
            label="核实详情"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="RectifyStatus"
            :formatter="statusNumFormatter"
            label="整改状态"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="RectifyMessage"
            label="整改情况"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="HandleAt"
            :formatter="dateFormatter"
            label="处理日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="Handler"
            label="处理人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="Resolution"
            label="处理措施"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text" size="mini"
                :disabled="scope.row.Status == 'FINISHED'"
                @click="handleDialogOpen(scope.row.Id, 'edit')"
              >处理</el-button>
              <el-button
                type="text" size="mini"
                @click="handleDialogOpen(scope.row.Id, 'view')"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <div class="toolbar" style="text-align:center">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      ></el-pagination>
    </div>
    <management-view-dialog ref="managementViewDialog" @on-refresh="getData"  />
  </section>
</template>

<script>
import ManagementViewDialog from "@/component/healthCodeManagement/ManagementViewDialog.vue";
import { mapState } from 'vuex'
import {
  dateFormater,
  getUserInfo,
  export_json, ownerJoin, maintenanceStatusFormatter,
} from '@/util'

import {maintenanceTypeFormatter} from '@/util'
import conf from '@/config'

export default {
  components: {ManagementViewDialog},
  name: 'HealthCodeRecordHandle',
  data() {
    return {
      conf,
      changeState: false,
      exportLoading: false,
      loading: false,
      statusOptions: [
        {
          label: '核实中',
          value: 'WAIT_TO_VERIFY'
        },
        {
          label: '整改中',
          value: 'WAIT_TO_RECTIFY'
        },
        {
          label: '处理中',
          value: 'WAIT_TO_HANDLE'
        },
        {
          label: '已完成',
          value: 'FINISHED'
        }
      ],
      datalist: {},
      userInfo: null,
      dlg: {
        dialogSubmitted: false,
        visible: false,
        isDeal: false,
        isView: false,
        showImg: false,
        dialogImageUrl: '',
        title: ''
      },
      filter: {
        name: null,
        field: 'name',
        Status: '',
        rectify_status: '',
        create_at: '',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      MaintainerList: [],
      fileList: [],
      formData: {},
      formRule: {
        Resolution: [{ required: true, message: '处理措施不可为空' }]
      }
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'locale', 'user'])
  },
  created() {
    this.userInfo = getUserInfo().Info
    this.getData()
  },
  methods: {
    /* 图片模块 start */
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.MaintenancePicS.push({ MaintenanceId: this.formData.Id, PicUrl: res.Data })
    },
    handleExceed(files, fileList) {
      this.$message.warning(`照片数量限制在4张`)
    },
    handleRemove(file, fileList) {
      this.formData.MaintenancePicS.forEach((v, index) => {
        if (file.response) {
          if (v.PicUrl === file.response.data) {
            this.formData.MaintenancePicS.splice(index, 1)
          }
        } else {
          if (v.PicUrl === file.url) {
            this.formData.MaintenancePicS.splice(index, 1)
          }
        }
      })
      const i = this.formData.MaintenancePicS.indexOf(file.response.data)
      this.formData.MaintenancePicS.splice(i, 1)
    },
    handlePreview(file) {
      this.dlg.showImg = true
      this.dlg.dialogImageUrl = file.response.Data
    },
    /* end */
    statusFormatter(r, c) {
      switch (r[c.property]) {
        case 'WAIT_TO_VERIFY':
          return '核实中'
        case 'WAIT_TO_RECTIFY':
          return '整改中'
        case 'WAIT_TO_HANDLE':
          return '处理中'
        case 'FINISHED':
          return '已完成'
        default:
          return '-'
      }
    },
    statusNumFormatter(r, c) {
      switch (r[c.property]) {
        case 1:
          return '整改中'
        case 2:
          return '整改完成'
        case 3:
          return '整改未完成'
        default:
          return '-'
      }
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({ value }) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    hasChange() {
      this.changeState = true
    },
    dateFormatter(r, c) {
      return dateFormater(r[c.property], false, false)
    },
    getData: function() {
      this.loading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        FormType: 1,
        Param: {}
      }
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        payload.Param['health_code_maintenance.owner'] = { S: 4, V: owner }
      }
      if (this.filter.Status) {
        payload.Param['health_code_maintenance.status'] = { S: 5, V: [this.filter.Status] }
      }
      if (this.filter.rectify_status) {
        payload.Param['health_code_maintenance.rectify_status'] = { S: 0, V: this.filter.rectify_status }
      }
      if (this.filter.create_at) {
        var time = `${this.filter.create_at / 1000},${this.filter.create_at / 1000 + 60 * 60 * 24 - 1}`
        payload.Param['health_code_maintenance.create_at'] = { S: 11, V: time }
      }
      if (this.filter.MaintainerId) {
        payload.Param['maintainer_id'] = { S: 2, V: this.filter.MaintainerId.toString() }
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleDialogOpen(id, mode) {
      this.$refs['managementViewDialog'].dealDialog(mode, { id: id })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              // eslint-disable-next-line prefer-const
              let { Id, Resolution, Cause, MaintenancePicS } = this.formData
              const Status = 'FINISHED'
              MaintenancePicS = MaintenancePicS.map(item => {
                return item.PicUrl
              })
              this.$post('admin/updateHealthCodeRecord', { Id, Resolution, Cause, Status, MaintenancePicS })
                .then(() => {
                  this.dlg.visible = false
                })
            })
            .finally(() => {
              this.dlg.dialogSubmitted = true
            })
        }
      })
    },
    handleClose() {
      if (this.dlg.dialogSubmitted) {
        this.handleRefresh()
      }
      this.dlg.isView = false
      this.dlg.isDeal = false
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const payload = {
        StartAt: 0 | (this.filter.page - 1) * this.filter.size / 1000,
        Size: exportSize,
        FormType: 1,
        IsDownload: true,
        Param: {}
      }
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        payload.Param['health_code_maintenance.owner'] = { S: 4, V: owner }
      }
      if (this.filter.Status) {
        payload.Param['health_code_maintenance.status'] = { S: 5, V: [this.filter.Status] }
      }
      if (this.filter.rectify_status) {
        payload.Param['health_code_maintenance.rectify_status'] = { S: 0, V: this.filter.rectify_status }
      }
      if (this.filter.create_at) {
        var time = `${this.filter.create_at / 1000},${this.filter.create_at / 1000 + 60 * 60 * 24 - 1}`
        payload.Param['health_code_maintenance.create_at'] = { S: 11, V: time }
      }
      if (this.filter.MaintainerId) {
        payload.Param['maintainer_id'] = { S: 2, V: this.filter.MaintainerId.toString() }
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    }
  }
}
</script>
<style>
.el-image-viewer__close {
  background-color: #ECF4FB;
}
</style>
