<template>
  <div>
    <el-dialog
      :title="mode==='view'?'查看':'处理'"
      :visible.sync="dialogFormVisible"
      width="50%"
      :before-close="handleClose">
      <div class="block">
        <el-timeline>
          <el-timeline-item timestamp="数据情况" placement="top">
            <el-card>
              <el-table
                :data="[info]"
                size="mini"
                border
              >
                <el-table-column
                  prop="StartAt"
                  :formatter="dateFormat"
                  label="起始日期"
                  align="center"
                  width="100"
                  header-align="center"
                />
                <el-table-column
                  prop="CompliantDays"
                  label="达标天数"
                  width="100"
                  align="center"
                  header-align="center"
                >
                <template slot-scope="scope">
                    <span>{{ scope.row.CompliantDays || "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ExcessDays"
                  label="超标天数"
                  width="100"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.ExcessDays || "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="FilterAbnormallyUsedDays"
                  label="不正常使用净化器天数"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="OfflineCausedByShopDays"
                  label="店家原因离线天数"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="HealthCodeColor"
                  width="110"
                  label="油烟健康码"
                  sortable="custom"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span class="iconfont Red">&#xe704;</span>
                  </template>
                </el-table-column>
              </el-table>

            </el-card>
          </el-timeline-item>
          <template v-if="info.Status == 'FINISHED' && info.VerifyAt == 0">
            <template v-if="info.Resolution">
              <el-timeline-item :timestamp="`连续${info.Locale.HealthCodeValue6}达标排放自动转绿,事件完结`" placement="top">
              </el-timeline-item>
            </template>
            <template v-else>
              <el-timeline-item timestamp="点位满足绿码条件自动转绿,事件完结" placement="top">
              </el-timeline-item>
            </template>
          </template>
          <template v-else>
            <el-timeline-item timestamp="红码核实" placement="top">
              <el-card>
                <el-form
                  :model="formData"
                  :rules="formRule"
                  ref="form"
                  size="mini"
                  label-width="108px"
                  label-position="left"
                  :disabled="dlg.isView"
                >
                  <el-form-item prop="VerifyAt" label="核实日期">
                    <el-date-picker
                      disabled
                      placeholder="核实日期"
                      type="date"
                      value-format="timestamp"
                      format="yyyy-MM-dd"
                      v-model="formData.VerifyAt"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    prop="Status"
                    label="是否属实">
                    <el-select v-model="formData.Status">
                      <template v-if="formData.RectifyStatus > 0">
                        <el-option label="属实" disable value="WAIT_TO_RECTIFY" />
                        <el-option label="属实" disable value="WAIT_TO_HANDLE" />
                        <el-option label="不属实" disable value="WAIT_TO_CHECK" />
                        <template v-if="formData.Status=='FINISHED'">
                          <template v-if="formData.RectifyMessage=='不属实'">
                            <el-option label="不属实" disable value="FINISHED" />
                          </template>
                          <template v-else>
                            <el-option label="属实" disable value="FINISHED" />
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <el-option label="属实" value="WAIT_TO_RECTIFY" />
                        <el-option label="不属实" value="WAIT_TO_CHECK"/>
                      </template>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="RectifyReason"
                    label="红码原因"
                  >
                    <el-select v-model="formData.RectifyReason">
                      <el-option label="净化器需清洗" value="净化器需清洗"/>
                      <el-option label="净化器故障" value="净化器故障"/>
                      <el-option label="净化器效率低" value="净化器效率低"/>
                      <el-option label="净化器未使用" value="净化器未使用"/>
                      <el-option label="店家歇业" value="店家歇业"/>
                      <el-option label="店家停业" value="店家停业"/>
                      <el-option label="店铺线路故障" value="店铺线路故障"/>
                      <el-option label="设备原因" value="设备原因"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="Cause"
                    label="核实详情"
                  >
                    <el-input
                      type="textarea"
                      autosize
                      v-model.trim="formData.Cause"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="RectifyDate"
                    label="整改期限"
                  >
                    <el-select v-model="formData.RectifyDate">
                      <el-option label="3" :value="3"/>
                      <el-option label="4" :value="4"/>
                      <el-option label="5" :value="5"/>
                      <el-option label="6" :value="6"/>
                      <el-option label="7" :value="7"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="Status"
                    label="获取定位">
                    <baidu-map
                      class="bm-view"
                      :center="mapCenter"
                      :zoom="mapZoom"
                      ak="770b4c07458f53896ff0abd948755e20"
                      @ready="handleMapReady"
                      v-if="mode == 'edit'"
                    >
                      <bm-geolocation
                        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                        :showAddressBar="true"
                        :autoLocation="true"
                        @locationSuccess="getPosition"
                      ></bm-geolocation>
                    </baidu-map>
                    <span>{{ formData.Addr ? formData.Addr : '暂无定位' }}</span>
                  </el-form-item>
                  <el-form-item
                    prop="MaintenancePicS"
                    label="核实照片">
                    <div v-if="mode == 'view' || (mode == 'edit' && info.Status !='WAIT_TO_VERIFY')">
                      <el-image v-for="item in fileList" :key="item" :src="item" :preview-src-list="fileList" style="width: 100px;height: 100px;margin-left:10px;" />
                    </div>
                    <el-upload
                      ref="upload"
                      accept=".jpg, .jpeg, .png"
                      list-type="picture-card"
                      :action="upload()"
                      :on-success="handleAvatarSuccess"
                      :on-remove="handleRemove"
                      multiple
                      v-if="mode == 'edit' &&  info.Status =='WAIT_TO_VERIFY'"
                    >
                      <i class="el-icon-plus"/>
                      <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
                    </el-upload>
                  </el-form-item>
                </el-form>
                <div style="float:right;padding-bottom:20px;margin-right:20px;" v-if="mode == 'edit' && info.Status =='WAIT_TO_VERIFY'">
                  <el-button type="primary" size="mini" @click.native="handleSubmit(true)">提交</el-button>
                </div>
                <h4>核实状态: <template v-if="info.Status != 'WAIT_TO_VERIFY'"><span style="color:#4091ff;margin-left:38px;">已完成</span></template><template v-else><span style="color:#aaaaaa;margin-left:38px;">待核实</span></template></h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item v-if="info.Status == 'WAIT_TO_CHECK'" timestamp="事件审核" placement="top">
              <el-card>
                <el-form
                  :model="formData2"
                  :rules="formRule2"
                  ref="form2"
                  size="mini"
                  label-width="108px"
                  label-position="left"
                  :disabled="dlg.isCheckView">
                  <el-form-item
                    prop="Status"
                    label="是否通过">
                    <el-select v-model="formData2.Status">
                      <el-option label="通过" value="FINISHED"/>
                      <el-option label="不通过" value="WAIT_TO_VERIFY"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="Resolution"
                    label="核实详情"
                  >
                    <el-input
                      type="textarea"
                      autosize
                      v-model.trim="formData2.Resolution"
                    />
                  </el-form-item>
                </el-form>
                <div style="float:right;padding-bottom:20px;margin-right:20px;" v-if="mode == 'edit' && info.Status =='WAIT_TO_CHECK'">
                  <el-button type="primary" size="mini" @click.native="submitCheck()">提交</el-button>
                </div>
                <h4>处理状态: <template v-if="info.Status != 'FINISHED'"><span style="color:#4091ff;margin-left:38px;">审核中</span></template><template v-else><span style="color:#aaaaaa;margin-left:38px;">已完成</span></template></h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item v-if="info.RectifyStatus > 0 && info.RectifyMessage != '不属实'" timestamp="整改情况" placement="top">
              <el-card>
                <h4>整改状态: <template v-if="info.RectifyStatus == 1"><span style="color:#aaaaaa;margin-left:38px;">整改中</span></template><template v-else-if="info.RectifyStatus == 2"><span style="color:#4091ff;margin-left:38px;">整改完成</span></template><template v-else><span style="color:#660000;margin-left:38px;">整改失败</span></template></h4>
                <h4>整改日期:  <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{fixTime(info.VerifyAt, 1)}}</span><span style="margin-left:20px;color:#aaaaaa;font-weight: 400;">~</span><span style="font-size: 14px;margin-left:20px;color:#aaaaaa;font-weight: 400;">{{fixTime(info.VerifyAt, info.RectifyDate)}}</span></h4>
                <el-table
                  :data="sumDataDaily"
                  size="mini"
                  border
                >
                  <el-table-column
                    prop="AcquitDate"
                    label="数据日期"
                    align="center"
                    width="100"
                    header-align="center"
                  />
                  <el-table-column
                    prop="Status"
                    label="当日状态"
                    align="center"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      <span>
                        <template v-if="scope.row.Status == 1">正常</template>
                        <template v-else-if="scope.row.Status == 2">超标</template>
                        <template v-else-if="scope.row.Status == 3">正常离线</template>
                        <template v-else>异常离线</template>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="CEmissions"
                    label="油烟浓度"
                    width="100"
                    align="center"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.CEmissions || "-" }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="FilterAbnormallyUsedDays"
                    label="不正常使用净化器"
                    align="center"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{
                          scope.row.FilterAbnormallyUsed === null
                            ? "-"
                            : scope.row.FilterAbnormallyUsed
                              ? "不正常"
                              : "正常"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="OnlineStatus"
                    label="店家正常在线"
                    align="center"
                    header-align="center"
                  >
                    <template slot-scope="scope">
                      <span>
                        <template v-if="scope.row.OnlineStatus == 1">正常</template>
                        <template v-else-if="scope.row.OnlineStatus == 2">正常离线</template>
                        <template v-else>异常离线</template>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <template v-if="washInfo.Id">
                  <h4>净化器清洗记录</h4>
                  <h4>监测点名称: <span style="font-size: 14px;margin-left:24px;color:#aaaaaa;font-weight: 400;">{{washInfo.LocaleName}}</span></h4>
                  <h4>开始日期: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{washInfo.BeginDate}}</span></h4>
                  <h4>结束日期: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{washInfo.EndDate}}</span></h4>
                  <h4>备注: <span style="font-size: 14px;margin-left:68px;color:#aaaaaa;font-weight: 400;">{{washInfo.Remark}}</span></h4>
                  <div>
                    <el-image v-for="item in washInfo.LocaleUploadedInfoPicS" :key="item" :src="item" :preview-src-list="washInfo.LocaleUploadedInfoPicS" style="width: 100px;height: 100px;margin-left:10px;" />
                  </div>
                </template>
              </el-card>
            </el-timeline-item>
            <el-timeline-item v-if="((info.Status == 'WAIT_TO_HANDLE' || info.Status == 'FINISHED') && info.RectifyStatus == 3)" timestamp="事件处理" placement="top">
              <el-card>
                <el-form
                  :model="formData1"
                  :rules="formRule1"
                  ref="form1"
                  size="mini"
                  label-width="108px"
                  label-position="left"
                  :disabled="dlg.isHandleView">
                  <template v-if="eventRecords.length>0">
                    <el-form-item label="处理记录">
                      <el-timeline>
                        <el-timeline-item v-for="item in eventRecords" :key="item.index" :timestamp="'第'+item.index+'次处理记录'" placement="top">
                          <el-card>
                            <h4>处理日期: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{dateFormatHsm(item.handle_at)}}</span></h4>
                            <h4>处理人员: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.handle_name}}</span></h4>
                            <h4>处理措施: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.solution}}</span></h4>
                            <div v-if="item.handle_pics.length > 0">
                              <el-image v-for="(pic,index) in item.handle_pics" :key="index" :src="pic" :preview-src-list="item.handle_pics" style="width: 100px;height: 100px;margin-left:10px;" />
                            </div>
                          </el-card>
                        </el-timeline-item>
                      </el-timeline>
                    </el-form-item>
                  </template>
                  <el-form-item prop="VerifyAt" label="处理日期">
                    <el-date-picker
                      disabled
                      placeholder="处理日期"
                      type="date"
                      value-format="timestamp"
                      format="yyyy-MM-dd"
                      v-model="formData1.HandleAt"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    prop="Resolution"
                    label="处理措施">
                    <el-input
                      type="textarea"
                      autosize
                      v-model.trim="formData1.Resolution"/>
                  </el-form-item>
                  <el-form-item
                    prop="status"
                    label="处理结果"
                  >
                    <el-select
                      style="width: 100%"
                      v-model="formData1.Status"
                      clearable
                      placeholder='处理结果'
                    >
                      <el-option label="处理中" value="WAIT_TO_HANDLE"></el-option>
                      <el-option label="已完成" value="FINISHED"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="MaintenancePicS"
                    label="处理照片">
                    <div v-if="mode == 'view'">
                      <el-image v-for="item in handleList" :key="item" :src="item" :preview-src-list="handleList" style="width: 100px;height: 100px;margin-left:10px;" />
                    </div>
                    <el-upload
                      ref="upload"
                      accept=".jpg, .jpeg, .png"
                      list-type="picture-card"
                      :action="upload()"
                      :on-success="handleAvatar1Success"
                      :on-remove="handle1Remove"
                      multiple
                      v-if="mode == 'edit' &&  info.Status =='WAIT_TO_HANDLE'"
                    >
                      <i class="el-icon-plus"/>
                      <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
                    </el-upload>
                  </el-form-item>
                </el-form>
                <div style="float:right;padding-bottom:20px;margin-right:20px;" v-if="mode == 'edit' && info.Status =='WAIT_TO_HANDLE'">
                  <el-button type="primary" size="mini" @click.native="submit()">提交</el-button>
                </div>
                <h4>处理状态: <template v-if="info.Status != 'WAIT_TO_HANDLE'"><span style="color:#4091ff;margin-left:38px;">已完成</span></template><template v-else><span style="color:#aaaaaa;margin-left:38px;">处理中</span></template></h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="事件完结" placement="top" v-if="info.Status == 'FINISHED'">
            </el-timeline-item>
          </template>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormater } from '@/util/index'
import conf from '@/config'
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
export default {
  name: "ManagementViewDialog",
  components: {BaiduMap, BmGeolocation},
  data() {
    return {
      conf,
      info: {},
      fileList: [],
      handleList: [],
      formData: {},
      formRule: {
        Cause: [{required: true, message: '不可为空'}],
        Status: [{required: true, message: '不可为空'}],
        RectifyReason: [{required: true, message: '不可为空'}],
        Addr: [{required: true, message: '不可为空'}],
        MaintenancePicS: [{required: true, message: '不可为空'}],
      },
      formData1: {},
      formRule1: {
        Resolution: [{ required: true, message: '处理措施不可为空' }],
        MaintenancePicS: [{required: true, message: '不可为空'}],
      },
      formData2: {},
      eventRecords: [],
      formRule2: {
        Resolution: [{ required: true, message: '备注不可为空' }],
      },
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
      dlg: {
        isView: true,
        isHandleView: true,
        isCheckView: true,
      },
      dialogFormVisible: false,
      mode: 'view',
      sumDataDaily:[],
      washInfo: {},
    }
  },
  mounted() {
    // 通过遮罩层关闭图片预览
    document.addEventListener('click',function(e){
      if(e.target.className=="el-image-viewer__mask"){
        let close = document.querySelector(".el-image-viewer__btn");
        close.click();
      }
    })
  },
  methods: {
    dateFormat(r, c) {
      return dateFormater(r[c.property])
    },
    dateFormatHsm(r) {
      return dateFormater(r, true);
    },
    dealDialog(optType = 'view', row = {}) {
      this.id = row.id
      this.mode = optType
      this.dialogFormVisible = true
      this.getData()
    },
    getWashList() {
      this.washInfo = {}
      const tpl = { LocaleId: this.info.LocaleId }
      tpl["UploadType"] = "FilterWash";
      tpl['RecordAtStart'] = this.fixTime(this.info.VerifyAt, 0)
      tpl['RecordAtEnd'] = this.fixTime(this.info.VerifyAt, this.info.RectifyDate)
      this.$post('admin/queryLocaleUploadedInfo', tpl).then((res) => {
        if (res.content.length > 0) {
          let arrBegin = res.content[0].BeginDate.split(" ")
          res.content[0].BeginDate = arrBegin[0]
          let arrEnd = res.content[0].EndDate.split(" ")
          res.content[0].EndDate = arrEnd[0]
          this.washInfo = res.content[0]
        }
      })

    },
    getDailyData() {
      this.sumDataDaily = []
      const tpl = { localeId: this.info.LocaleId }
      tpl['AcquitAtBegin'] = this.fixTimestamp(this.info.VerifyAt, 0)
      if (this.info.RectifyStatus == 2) {
        if (this.info.HandleAt > 0) {
          tpl['AcquitAtEnd'] = this.fixTimestamp(this.info.HandleAt, 0) - 1
        } else {
          tpl['AcquitAtEnd'] = this.fixTimestamp(this.info.VerifyAt, this.info.RectifyDate + 1) - 1
        }
      } else {
        tpl['AcquitAtEnd'] = this.fixTimestamp(this.info.VerifyAt, this.info.RectifyDate + 1) - 1
      }
      this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
        for(var i = 0; i < res.length; i++) {
          let arr = res[i].AcquitDate.split(" ")
          res[i].AcquitDate = arr[0]
          this.sumDataDaily.unshift(res[i])
        }
      })
    },
    getEventInfo(id) {
      this.eventRecords = []
      this.$get('admin/listEvent/info/' + id,{typ: 1}).then(res => {
        for(var i = 0; i < res.eventHandleRecords.length; i++) {
          res.eventHandleRecords[i].index = i+1
          if (res.eventHandleRecords[i].handle_pics != "") {
            res.eventHandleRecords[i].handle_pics = res.eventHandleRecords[i].handle_pics.split(",")
          } else {
            res.eventHandleRecords[i].handle_pics = []
          }
        }
        this.eventRecords = res.eventHandleRecords
      })
    },
    getData() {
      this.info = {}
      this.$get('admin/listHealthCode/' + this.id).then(res => {
        var checkPics = []
        var handlePics = []
        for (var i = 0; i < res.MaintenancePics.length; i++) {
          if (res.MaintenancePics[i].Type == 1) {
            checkPics.push(res.MaintenancePics[i].PicUrl)
          } else {
            handlePics.push(res.MaintenancePics[i].PicUrl)
          }
        }
        if (res.Status == "WAIT_TO_VERIFY" && this.mode=='edit') {
          this.dlg.isView = false
        } else {
          this.dlg.isView = true
        }
        if (res.Status == "WAIT_TO_HANDLE" && this.mode=='edit') {
          this.dlg.isHandleView = false
        } else {
          this.dlg.isHandleView =  true
        }
        if (res.Status == "WAIT_TO_CHECK" && this.mode=='edit') {
          this.dlg.isCheckView = false
        } else {
          this.dlg.isCheckView =  true
        }
        this.formData = {
          Id: res.Id,
          Cause: res.Cause,
          LocaleName: res.LocaleName,
          Handler: res.Handler,
          MaintenancePicS: checkPics,
          Status: res.Status === 'WAIT_TO_VERIFY' ? '' : res.Status,
          RectifyDate: res.RectifyDate > 0 ?res.RectifyDate:3,
          RectifyReason: res.RectifyReason,
          RectifyStatus: res.RectifyStatus,
          RectifyMessage: res.RectifyMessage,
          Addr: res.Addr,
          VerifyAt: res.VerifyAt > 0 ? new Date(res.VerifyAt * 1000) : new Date(new Date().getTime())
        }
        this.formData1 = {
          Id: res.Id,
          Resolution: res.Resolution,
          MaintenancePicS: handlePics,
          HandleAt: res.HandleAt > 0 ? new Date(res.HandleAt * 1000) : new Date(new Date().getTime()),
          Status: 'FINISHED',
        }

        this.formData2 = {
          Id: res.Id,
          Status: 'FINISHED',
          Resolution: res.Resolution,
        }

        this.fileList = checkPics
        this.handleList = handlePics
        this.info = res
        if (res.RectifyStatus > 0) {
          this.getDailyData()
          this.getWashList()
          this.getEventInfo(this.id)
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
          .then(() => {
            let {Id, Cause, Addr, MaintenancePicS, Status, RectifyReason, RectifyDate} = this.formData
            this.$post('admin/updateHealthCodeRecord', {Id, Cause, Addr, MaintenancePicS, Status, RectifyReason, RectifyDate})
              .then(() => {
                setTimeout(()=> {
                  this.$emit('on-refresh')
                }, 500)
                this.$message({
                  type: 'success',
                  message: "审核成功"
                })
                this.dialogFormVisible = false
              })
          })
        }
      })
    },
    submit() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
              let { Id, Resolution,Status, MaintenancePicS } = this.formData1
              this.$post('admin/updateHealthCodeRecord', { Id, Resolution, Status, MaintenancePicS })
                .then(() => {
                  setTimeout(()=> {
                    this.$emit('on-refresh')
                  }, 500)
                  this.$message({
                    type: 'success',
                    message: "审核成功"
                  })
                  this.dialogFormVisible = false
                })
            })
        }
      })
    },
    submitCheck() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            let { Id, Resolution, Status } = this.formData2
            this.$post('admin/updateHealthCodeRecord', {Id, Resolution, Status,})
              .then(() => {
                setTimeout(()=> {
                  this.$emit('on-refresh')
                }, 500)
                this.$message({
                  type: 'success',
                  message: "审核成功"
                })
                this.dialogFormVisible = false
              })
          })
        }
      })
    },
    handleClose() {
      this.dialogFormVisible = false
      this.formData = {}
      this.formData1 = {}
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.MaintenancePicS.push(res.Data)
    },
    handleRemove(file, fileList) {
      const i = this.formData.MaintenancePicS.indexOf(file.response.Data)
      this.formData.MaintenancePicS.splice(i, 1)
    },
    handleAvatar1Success(res, file) {
      this.formData1.MaintenancePicS.push(res.Data)
    },
    handle1Remove(file, fileList) {
      const i = this.formData1.MaintenancePicS.indexOf(file.response.Data)
      this.formData1.MaintenancePicS.splice(i, 1)
    },
    handleMapReady() {
      if (this.formData.Lng && this.formData.Lat) {
        this.mapCenter = {lng: this.formData.Lng, lat: this.formData.Lat}
      }
    },
    getPosition(pos) {
      this.formData.Lng = pos.point.lng.toString()
      this.formData.Lat = pos.point.lat.toString()
      this.formData.Addr = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    fixTime(time, n) {
      if (time > 0) {
        var timestamp = time  + n * 86400
        const date = new Date(timestamp * 1000); // 将时间戳转换成毫秒
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month}-${day}`;
      }
      return "-"
    },
    fixTimestamp(time, n) {
      var date = new Date(time * 1000);
      date.setHours(0, 0, 0, 0); // 设置为凌晨0点
      return date.getTime() / 1000 + n * 86400
    },
  }
}
</script>
<style scoped>
.bm-view {
  width: 100%;
  height: 150px;
}
.el-image-viewer__close {
  background-color: #ECF4FB;
}
</style>
